<template lang="pug">
  transition(name="slide-fade")
    div
      loading(:active.sync="busy", :is-full-page="true")
      .widget
        .widget-header
          h1.title Placements
          i.la.la-2x.la-filter.d-flex.align-items-center.mobile(@click="areFiltersOpened = !areFiltersOpened")
        .widget-body
          filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'placementsList'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
            .form-row-main
              .form-group
                label Search
                input.form-control(type="text", placeholder="Keyword...", v-model="filters.keyword")
              select-publisher(v-model="filters.publisher_id")
              select-advertiser(v-model="filters.advertiser_id")
              select-offer(v-model="filters.offer_id")
              .form-group
                label Placement Status
                select.form-control(v-model="filters.placement_status")
                  option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
              .form-group
                label Offer Status
                select.form-control(v-model="filters.offer_status")
                  option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
              .form-group
                label Model
                select.form-control(v-model="filters.payout_model")
                  option(value="all") All
                  option(v-for="o in payoutModelOptions", :value="o.v") {{ o.v }}
              .form-group
                label OS
                select.form-control(v-model="filters.platform")
                  option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
              .form-group
                label Countries
                input.form-control(type="text", v-model="filters.countries", placeholder="US, GB...")

            .form-group.no-label.desktop
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download.mr-1
                    | Export
                  b-dropdown-item(href="/app/placements/default-settings")
                    i.la.la-cog.mr-1
                    | Default Settings

            .w-100.mobile.p-2.border-top.position-sticky.bg-white(style="bottom: 0; z-index: 2;")
              b-button-group.submit-filters-button
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download.mr-1
                    | Export
                  b-dropdown-item(href="/app/offers/default-settings")
                    i.la.la-cog.mr-1
                    | Default Settings


          p.clearfix &nbsp;


          .ov-table-wrapper
            .tabs.table-tabs
              ul.nav.nav-tabs
                li.nav-item
                  a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
                li.nav-item
                  a.nav-link(:class="{active:selectedTab==='bucket', highlight: bucket.length > 0}", @click="selectedTab='bucket'") Selected
                    span.ml-1.bucket-size ({{bucket.length}})
            table.table.table-bordered.table-offer-list.min-height
              thead
                tr
                  th(rowspan="2")
                    span(v-if="selectedTab==='search'")
                      a(href="javascript:void(0);", @click="toggleAll()", style="font-size: 18px;")
                        i.la(:class="{'la-check-square':toggleAllCheck, 'la-stop':!toggleAllCheck}")

                    span(v-if="selectedTab==='bucket'")
                      a(href="javascript:void(0);", @click="clearBucket()") Clear
                  th(rowspan="2")
                    .text ID
                  th(style="width:45px;", rowspan="2") &nbsp;
                  th(rowspan="2")
                    .text Publisher
                  th(rowspan="2")
                    .text Advertiser
                  th(rowspan="2")
                    .text Offer

                  th(:colspan="expandedColumns.settings ? 4 : 2")
                    .d-flex.justify-content-between
                      span Settings
                      a(href="javascript:void(0);", @click="toggleColumn('settings')")
                        i.la(:class="{'la-plus': !expandedColumns.settings, 'la-minus': expandedColumns.settings}")
                  th(colspan="2") Click Cap
                  th(colspan="3") Conversion Cap
                  th(colspan="2") Pacing
                  th(rowspan="2") Install Cap

                  th(:colspan="expandedColumns.advanced ? 4 : 2")
                    .d-flex.justify-content-between
                      span Advanced
                      a(href="javascript:void(0);", @click="toggleColumn('advanced')")
                        i.la(:class="{'la-plus': !expandedColumns.advanced, 'la-minus': expandedColumns.advanced}")

                  th(rowspan="2")
                    .text Status
                  th(rowspan="2")
                    .text Comments
                  th(rowspan="2")
                    .text()
                      b-dropdown(text="Bulk Action", variant="link")
                        b-dropdown-item(@click="bulkSetStatus('paused')")
                          i.la.la-pause-circle.mr-2
                          | Pause
                        b-dropdown-item(@click="bulkSetStatus('live')")
                          i.la.la-play-circle.mr-2
                          | Activate
                        b-dropdown-item(@click="bulkSetStatus('cancelled')")
                          i.la.la-stop-circle.mr-2
                          | Cancel
                        b-dropdown-item(@click="bulkModal('payout', 'Set Payout')")
                          i.la.la-dollar-sign.mr-2
                          | Set payout
                        b-dropdown-item(@click="bulkModal('risk_management', 'Set Risk')", v-if="USER.permissions['placements::RISK']")
                          i.la.la-percent.mr-2
                          | Set Risk
                        b-dropdown-item(@click="bulkModal('daily_conversion_cap', 'Set Conversion Cap')")
                          i.la.la-shield-alt.mr-2
                          | Set Conv. Cap
                        b-dropdown-item(@click="bulkModal('daily_click_cap', 'Set Click Cap')")
                          i.la.la-shield-alt.mr-2
                          | Set Click Cap
                        b-dropdown-item(@click="bulkModal('daily_impression_cap', 'Set Impression Cap')")
                          i.la.la-shield-alt.mr-2
                          | Set Impression Cap
                        b-dropdown-item(@click="bulkModal('impression_rate', 'Set Impression Ratio')", v-if="USER.config && USER.config.impressionRate")
                          i.la.la-percent.mr-2
                          | Set Impression Ratio
                        b-dropdown-divider
                        b-dropdown-item(@click="bulkExport()")
                          i.la.la-download.mr-2
                          | Export
                tr
                  th(style="min-width: 100px") Offer Payout
                  th(style="min-width: 100px") Placement Payout
                  th(v-if="expandedColumns.settings") GEO
                  th(v-if="expandedColumns.settings") OS

                  th Clicks
                  th Impressions
                  th Visible
                  th Actual
                  th Offer
                  th Clicks (m / H)
                  th Impressions (m / H)

                  th(v-b-tooltip.hover.right, title="Override Risk") R
                  th Risk
                  th(v-if="expandedColumns.advanced && USER.config && USER.config.impressionRate", style="min-width: 70px") Imp Ratio
                  th(v-if="expandedColumns.advanced", style="width: 60px") URL

              tbody
                tr(v-if="!records.length")
                  td(colspan="21") No matching records were found
                tr(v-for="r in (selectedTab==='bucket'?bucket:records)", v-bind:key="r.id", :class="{'row-selected': bucketHash[r.id]}")
                  td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
                    i.la.la-lock(v-if="r.reserved", v-b-tooltip.hover.right, title="This placement is reserved for system usage")
                    a(href="javascript:void(0)", @click="toggleBucket(r)", v-if="!r.reserved")
                      i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
                    i.la.la-robot(v-if="r.auto_generated", v-b-tooltip.hover.right, title="This placement managed by Auto-Connect settings")
                  td
                    div {{ r.id }}
                    offer-paused-reason(:offer="r.offer", :placement="r")
                    div.s2s-badge(v-if="r.s2s") S2S
                  td.col-status
                    div.status-block
                      button.ps.placement-status(:class="'status-'+r.status", v-b-tooltip.hover.top, title="Placement Status") &nbsp;
                      button.ps.offer-status(:class="'status-'+r.offer_status", v-b-tooltip.hover.top, title="Offer Status") &nbsp;
                      button.ps.final-status(:class="'status-'+r.effective_status", v-b-tooltip.hover.bottom, :title="r.effective_status==='active' ? 'Currently Active' : 'Currently Inactive'") &nbsp;
                  td.no-wrap
                    entity(:id="r.publisher_id", :name="r.publisher_name", type="publisher", @click="appendEntity(r,'publisher')")
                  td.no-wrap
                    entity(:id="r.advertiser_id", :name="r.advertiser_name", type="advertiser", @click="appendEntity(r,'advertiser')")
                  //td
                    router-link(:to="{name:'offer-edit', params:{id: r.offer_id}}") {{ r.offer_id }}
                  td.edit-inline
                    entity(:id="r.offer_id", :name="r.offer_name", type="offer", @click="appendEntity(r,'offer')")
                    div.created_at {{ r.created_at }}
                  td
                    div(v-if="r.offer_id")
                      div(style="height:21px") {{ r.offer_payout }} {{ r.offer_currency }}
                      div {{ r.offer_payout_type }}
                  td
                    div.payout-wrapper
                      span(v-b-tooltip.hover, title="Auto Payout")
                        b-form-checkbox(v-if="!r._edit_payout", v-model="r._auto_payout", @change="updateAutoPayout(r)", :disabled="!!r.reserved")
                      span.auto-payout-value(v-if="r._auto_payout") {{ r.payout }} {{ r.currency }}
                      quick-edit(v-if="!r._auto_payout", field="payout", :r="r", @on-save="updateField", :wrapped="false")
                    div(:class="{'font-weight-bold': r.offer_payout_type !== r.payout_type}") {{ r.payout_type }}
                      span.ml-1(v-if="r.payout_type === 'CPA' && r.payable_event_name") [{{r.payable_event_name}}]
                  td(v-if="expandedColumns.settings") {{ r.offer_country.join(', ') }}
                  td(v-if="expandedColumns.settings") {{ r.offer_platform }}
                  //td {{ r.timelag }}
                  td.no-wrap.edit-inline(style="width: 90px", v-if="+r.click_ratio_cap === 0")
                    .quickedit-buttons(v-if="!r.reserved")
                      a.edit-button(href="javascript:void(0)", @click="editPlacementCaps(r)")
                        i.la.la-pencil
                    div {{ r.current_click_count | shortNumber }} / {{ r.daily_click_cap | shortNumber }}
                  td.no-wrap.edit-inline(style="width: 90px", v-if="+r.click_ratio_cap === 0")
                    .quickedit-buttons(v-if="!r.reserved")
                      a.edit-button(href="javascript:void(0)", @click="editPlacementCaps(r)")
                        i.la.la-pencil
                    div {{ r.current_impression_count | shortNumber }} / {{ r.daily_impression_cap | shortNumber }}
                  td.no-wrap.edit-inline(style="width: 180px", v-if="+r.click_ratio_cap > 0", colspan="2")
                    .quickedit-buttons(v-if="!r.reserved")
                      a.edit-button(href="javascript:void(0)", @click="editPlacementCaps(r)")
                        i.la.la-pencil
                    div {{ r.click_ratio_cap }}% (Non-RW / Total)
                  td {{ r.current_reported_conversion_count | numberCommas }} / {{ r.visible_conversion_cap | numberCommas }}
                  td {{ r.current_conversion_count | numberCommas }} / {{ r.daily_conversion_cap | numberCommas }}
                  td {{ r.offer_daily_conversion_cap | numberCommas }}

                  td.no-wrap.edit-inline
                    .quickedit-buttons
                      a.edit-button(href="javascript:void(0)", @click="editPlacementCaps(r)")
                        i.la.la-pencil
                    div {{ r.click_pacing | numberCommas }} / {{ r.click_pacing_hour | numberCommas }}
                  td.no-wrap.edit-inline
                    .quickedit-buttons
                      a.edit-button(href="javascript:void(0)", @click="editPlacementCaps(r)")
                        i.la.la-pencil
                    div {{ r.impression_pacing | numberCommas }} / {{ r.impression_pacing_hour | numberCommas }}

                  td.no-wrap.edit-inline(style="width: 90px")
                    .quickedit-buttons(v-if="!r.reserved")
                      a.edit-button(href="javascript:void(0)", @click="editPlacementCaps(r)")
                        i.la.la-pencil
                    div {{ r.current_daily_install_count | numberCommas }} / {{ r.daily_install_cap | numberCommas }}

                  td
                    span(v-b-tooltip.hover, title="Override Risk", v-if="USER.permissions['placements::RISK']")
                      b-form-checkbox(v-model="r._custom_report", @change="updateCustomReport(r)", :disabled="!!r.reserved")
                  td.no-wrap
                    span(v-if="!USER.permissions['placements::RISK']") {{ r.custom_report ? r.risk_management : r.publisher_auto_report_risk }} %
                    div.risk-wrapper(v-if="USER.permissions['placements::RISK']")
                      div.auto-risk-value(v-if="!r._edit_risk_management")
                        div(v-if="!r.custom_report && r.publisher_id")
                          span.nowrap(v-if="r.publisher_auto_report") {{ r.publisher_auto_report_risk }} %
                          span(v-if="!r.publisher_auto_report") &nbsp;
                      quick-edit(v-if="r.custom_report", field="risk_management", :r="r", @on-save="updateField", :wrapped="false")
                  td.no-wrap(v-if="expandedColumns.advanced && USER.config && USER.config.impressionRate")
                    impression-ratio-display(:value="r.impression_rate")
                  td.quick-links(v-if="expandedColumns.advanced")
                    a.btn.btn-sm.btn-secondary.mr-1(href="javascript:void(0)", :id="`pop-click_url-${r.id}`", v-b-tooltip.hover, title="Show Click URL")
                      i.la.la-link
                    b-popover(:target="`pop-click_url-${r.id}`", placement="left", title="Click URL")
                      div.quickview-url.form-control {{ r.click_url }}

                    a.btn.btn-sm.btn-secondary(href="javascript:void(0)", :id="`pop-preview_url-${r.id}`", v-b-tooltip.hover, title="Show Preview URL")
                      i.la.la-search
                    b-popover(:target="`pop-preview_url-${r.id}`", placement="left", title="Preview URL")
                      div.quickview-url.form-control {{ r.preview_url }}

                  td.col-status
                    b-dd(:text="r.status", :class="'status-'+r.status", :disabled="!!r.reserved || !!r.auto_generated")
                      b-dropdown-item(v-for="o in statusOptions", v-bind:key="o.v", :class="'option-'+o.v", @click="updateStatus(r, o.v)") {{ o.t }}
                  td.edit-inline
                    quick-edit(field="comments", :r="r", @on-save="updateField", :wrapped="true", type="textarea")
                  td.actions
                    .action-buttons(v-if="!r.reserved")
                      router-link.btn.btn-action-default(v-b-tooltip.hover.bottom, title="Edit", :to="{name:'placement-edit', params:{id:r.id}}")
                        i.la.la-edit
                      // router-link.btn.btn-action-default(v-b-tooltip.hover.bottom, title="Optimize", :to="{name:'placement-targeting', params:{id:r.id}}")
                        i.la.la-line-chart
                      // button.btn.btn-action-danger(v-b-tooltip.hover.bottom, title="Delete")
                        i.la.la-trash
                      router-link.btn.btn-action-default(v-b-tooltip.hover.bottom, title="Statistics", :to="{name:'reports', query:{placement_id:r.id}}")
                        i.la.la-bar-chart
                      router-link.btn.btn-info(v-b-tooltip.hover.bottom, title="Test Integration", :to="{ name: 'placement-test', params: { id: r.id } }")
                        i.la.la-microscope

          paginate(:paginator="paginate", @update-page-size="loadData")

          b-modal(id="bulk-modal", :title="bulkModalTitle", @ok="bulkModalConfirm()")
            form
              .form-group(v-if="bulkModalField==='payout'")
                label Format
                select.form-control(v-model="bulkModalFormat")
                  option(value="fixed") Fixed
                  option(value="percent") Percent

              .form-group(v-if="bulkModalField==='risk_management'")
                label Action
                select.form-control(v-model="bulkModalFormat")
                  option(value="value") Set value
                  option(value="reset") Reset

              .form-group(v-if="bulkModalField !== 'impression_rate'")
                label Value
                input.form-control(v-model="bulkModalValue")

              impression-ratio(v-model="bulkModalValue", v-if="bulkModalField === 'impression_rate'")


</template>
<style lang="scss">
.s2s-badge {
  background: #e8e7e3;
  border-radius: 5px;
  font-size: 10px;
  text-align: center;
  padding: 0 4px;
}

table.table-offer-list {
  thead {
    tr {
      th {}
    }
  }

  .created_at {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 10px;
    color: #aaa;
  }

  tbody {
    tr {
      td {
        padding: 3px;

        .status-block {
          display: inline-block;
          width: 61px;
          position: relative;
          height: 30px;

          button.ps {
            border: none;
          }

          .placement-status {
            position: absolute;
            left: 0;
            width: 30px;
            height: 20px;
            border-radius: 5px 0 0 0;
          }

          .offer-status {
            position: absolute;
            left: 31px;
            width: 30px;
            height: 20px;
            border-radius: 0 5px 0 0;
          }

          .final-status {
            position: absolute;
            width: 100%;
            left: 0;
            height: 9px;
            top: 21px;
            border-radius: 0 0 5px 5px;
          }
        }

        &.quick-links {
          .btn {
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            padding: 0;
            text-align: center;
            font-size: 16px;
            margin: 1px;
            background: #ccc;
          }
        }
      }
    }
  }
}
</style>
<script>
import Vue from 'vue';
import QuickEditPlacementModal from '../modals/QuickEditPlacementModal';

export default {
  name: 'PlacementList',
  async beforeRouteEnter(to, from, next) {
    let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(to.query.offer_id);
    next((vm) => {
      vm.filters.offer_id = selectedOfferOptions;
      vm.loadData();
      return vm;
    });
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  data() {
    const defaultFilters = {
      advertiser_id: [],
      publisher_id: [],
      offer_id: [],
      keyword: '',
      app: null,
      has_blacklist: false,
      has_whitelist: false,
      platform: 'all',
      offer_status: 'live',
      placement_status: 'live',
      validation_status: 'all',
      countries: '',
      payout_model: 'all',
    };
    return {
      areFiltersOpened: false,
      busy: false,

      expandedColumns: {
        settings: false,
        performance: false,
        advanced: false,
      },
      bulkModalField: null,
      bulkModalTitle: null,
      bulkModalValue: 0,
      bulkModalFormat: 'fixed',

      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      selectedTab: 'search',
      bucket: [],
      bucketHash: {},
      selectedOffers: [],
      defaultFilters,
      filters: { ...defaultFilters },
      records: [],
      payoutModelOptions: Vue.ovData.general.payoutTypeOptions,
      statusOptions: [
        { v: 'all', t: 'All' },
        { v: 'draft', t: 'Draft' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'cancelled', t: 'Cancelled' },
        // { v: 'ended', t: 'Ended' },
        // { v: 'auto-pause', t: 'Auto Pause' },
      ],
      platformOptions: [
        { v: 'all', t: 'All' },
        { v: 'android', t: 'Android' },
        { v: 'ios', t: 'iOS' },
        { v: 'mobile_web', t: 'MobileWeb' },
        { v: 'desktop', t: 'Desktop' },
      ],
      sortBy: {
        field: 'id',
        direction: 'desc',
        sort: null,
      },
      toggleAllCheck: false,
    };
  },
  // filters: {
  // 	filterStatus: function(v, showInactive) {
  // 		return showInactive || v.status;
  // 	}
  // },
  methods: {
    toggleColumn: function (group) {
      Vue.set(this.expandedColumns, group, !this.expandedColumns[group]);
    },
    async bulkSetStatus(status) {
      let selectedIds = this.bucket.map((r) => r.id);
      if (selectedIds.length === 0) {
        return this.$ovNotify.error('No items selected');
      }
      try {
        let data = {
          status,
          placement_id: selectedIds,
        };
        this.busy = true;
        await this.$ovReq.post('placement/bulkSetStatus', data);
        this.bucket.forEach((r) => {
          Vue.set(r, 'status', status);
        });
        this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    // bulkSetPayout(){
    // 	let selectedIds = this.bucket.map(r => r.id);
    // 	if (selectedIds.length === 0) {
    // 		return this.$ovNotify.error('No items selected');
    // 	}
    // 	this.$bvModal.show('bulk-payout-modal');
    // },
    // async bulkSetPayoutConfirm(){
    // 	let selectedIds = this.bucket.map(r => r.id);
    // 	let data = {
    // 		placement_id: selectedIds,
    // 		payout: this.bulkPayoutValue
    // 	};
    // 	try {
    // 		this.busy = true;
    // 		await this.$ovReq.post('placement/bulkSetPayout', data);
    // 		this.bucket.forEach(r => {
    // 			let payout = +this.bulkPayoutValue;
    // 			Vue.set(r, 'payout', payout);
    // 			Vue.set(r, '_payout', payout);
    // 			r.payout = payout;
    // 		});
    // 		this.loadData();
    // 	} catch (e){
    // 		console.error(e);
    // 	}
    // 	this.busy = false;
    // 	this.$nextTick(() => {
    // 		this.$bvModal.hide('modal-prevent-closing');
    // 		this.loadData();
    // 	});
    // },

    // bulkSetRisk(){
    // 	let selectedIds = this.bucket.map(r => r.id);
    // 	if (selectedIds.length === 0) {
    // 		return this.$ovNotify.error('No items selected');
    // 	}
    // 	this.$bvModal.show('bulk-risk-modal');
    // },
    // async bulkSetRiskConfirm(){
    // 	let selectedIds = this.bucket.map(r => r.id);
    // 	let data = {
    // 		placement_id: selectedIds,
    // 		risk_management: this.bulkRiskValue
    // 	};
    // 	try {
    // 		this.busy = true;
    // 		await this.$ovReq.post('placement/bulkSetRisk', data);
    // 		this.bucket.forEach(r => {
    // 			let risk = +this.bulkRiskValue;
    // 			Vue.set(r, 'risk_management', risk);
    // 			Vue.set(r, '_risk_management', risk);
    // 			Vue.set(r, 'custom_report', true);
    // 			r.risk_management = risk;
    // 		});
    // 		this.loadData();
    // 	} catch (e){
    // 		console.error(e);
    // 	}
    // 	this.busy = false;
    // 	this.$nextTick(() => {
    // 		this.$bvModal.hide('modal-prevent-closing');
    // 		this.loadData();
    // 	});
    // },

    bulkModal(field, title) {
      let selectedIds = this.bucket.map((r) => r.id);
      if (selectedIds.length === 0) {
        return this.$ovNotify.error('No items selected');
      }
      this.bulkModalField = field;
      this.bulkModalTitle = title;
      this.bulkModalValue = 0;
      if (field === 'risk_management') {
        this.bulkModalFormat = 'fixed';
      } else if (field === 'payout') {
        this.bulkModalFormat = 'value';
      }
      this.$bvModal.show('bulk-modal');
    },

    async bulkModalConfirm() {
      let selectedIds = this.bucket.map((r) => r.id);
      let data = {
        placement_id: selectedIds,
        field: this.bulkModalField,
        value: this.bulkModalValue,
        format: this.bulkModalFormat,
      };
      try {
        this.busy = true;
        let resp = await this.$ovReq.post('placement/bulkSet', data);
        this.bucket.forEach((r) => {
          for (let f in resp.field) {
            let v = resp.field[f];
            Vue.set(r, f, v);
            Vue.set(r, '_' + f, v);
          }
        });
        this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
        this.loadData();
      });
    },

    async bulkExport() {
      let selectedIds = this.bucket.map((r) => r.id);
      if (selectedIds.length === 0) {
        return this.$ovNotify.error('No items selected');
      }
      let data = {
        placement_id: selectedIds,
      };
      try {
        this.busy = true;
        let resp = await this.$ovReq.post('placement/bulkExport', data);
        window.open(resp.path);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    clearBucket() {
      this.bucket = [];
      this.bucketHash = {};
    },
    toggleAll() {
      this.toggleAllCheck = !this.toggleAllCheck;
      this.records.forEach((r) => {
        if (this.toggleAllCheck) {
          if (!this.bucketHash[r.id]) {
            this.toggleBucket(r);
          }
        } else {
          if (this.bucketHash[r.id]) {
            this.toggleBucket(r);
          }
        }
      });
    },
    toggleBucket(r) {
      if (r.reserved) return;
      if (this.bucketHash[r.id]) {
        Vue.set(this.bucketHash, r.id, false);
        for (let i = 0; i < this.bucket.length; i++) {
          if (this.bucket[i].id === r.id) {
            this.bucket.splice(i, 1);
            return;
          }
        }
      } else {
        Vue.set(this.bucketHash, r.id, r);
        this.bucket.push(r);
      }
    },
    updateAutoPayout(r) {
      setTimeout(() => {
        this.updateField(r, 'auto_payout');
      });
    },
    updateCustomReport(r) {
      setTimeout(() => {
        this.updateField(r, 'custom_report');
      });
    },
    // updateAutoReport(r){
    // 	setTimeout(() => {
    // 		this.updateField(r, 'auto_report');
    // 	});
    // },
    updateStatus(r, v) {
      r._status = v;
      setTimeout(() => {
        this.updateField(r, 'status');
      });
    },
    async updateField(r, field) {
      let data = {
        id: r.id,
        key: field,
        field: field,
        val: r['_' + field],
        value: r['_' + field],
      };
      if (Vue.ovData.placement._booleans.indexOf(field) > -1) {
        data.val = data.val ? 1 : 0;
        data.value = data.value ? 1 : 0;
      }
      try {
        let resp = await this.$ovReq.post('placement/updateField', data);
        r['_edit_' + field] = false;
        r[field] = resp.entity[field];
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
    async loadData(doExport) {
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        countries: this.filters.countries,
        offer_status: this.filters.offer_status,
        placement_status: this.filters.placement_status,
        validation_status: this.filters.validation_status,
        platform: this.filters.platform,
        payout_model: this.filters.payout_model,
        sort_col: this.sortBy.field,
        sort_dir: this.sortBy.direction,
      };
      try {
        let resp = await this.$ovReq.get('placement/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.records.forEach((r) => {
            Vue.set(r, '_edit_risk_management', false);
            Vue.set(r, '_risk_management', r.risk_management);
            Vue.set(r, '_edit_payout', false);
            Vue.set(r, '_payout', r.payout);
            Vue.set(r, '_auto_payout', r.auto_payout);
            Vue.set(r, '_custom_report', r.custom_report);
            //Vue.set(r, '_auto_report', r.auto_report);
            Vue.set(r, '_api_enabled', r.api_enabled);
            Vue.set(r, '_status', r.status);
            if (this.bucketHash[r.id]) {
              console.log('found in hash');
              for (let k in r) {
                Vue.set(this.bucketHash[r.id], k, r[k]);
              }
              //Vue.set(this.bucketHash, r.id, r);
            }
          });
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },

    editPlacementCaps(r) {
      this.$modal.show(
        QuickEditPlacementModal,
        {
          id: r.id,
          caps_only: true,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            await this.loadData();
          },
        },
      );
    },

    appendEntity(record, entityType) {
      switch (entityType) {
        case 'offer':
          this.filters.offer_id.push({
            id: record.offer_id,
            name: `[${record.offer_id}] ${record.offer_name}`,
          });
          break;
        case 'advertiser':
          this.filters.advertiser_id.push({
            v: record.advertiser_id,
            t: `[${record.advertiser_id}] ${record.advertiser_name}`,
          });
          break;
        case 'publisher':
          this.filters.publisher_id.push({
            v: record.publisher_id,
            t: `[${record.publisher_id}] ${record.publisher_name}`,
          });
          break;
      }
      this.$forceUpdate();
    },
  },
};
</script>
